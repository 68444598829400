import React from 'react'
import './Footer.css'
import InstagramFeed from './InstagramFeed'

export default () => (
  <div>
    <h2 className="taCenter">
      Síguenos en{' '}
      <a
        href="https://instagram.com/famacafeteria"
        target="_blank"
        rel="noopener noreferrer"
      >
        @famacafeteria
      </a>
    </h2>
    <br />
    {/* <InstagramFeed count="8" /> */}
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Crafted by{' '}
          <a
            href="https://www.linkedin.com/in/francisco-jose-garcia-meseguer-6b3086142"
            target="_blank"
            rel="noopener noreferrer"
          >
            frgarciames
          </a>
          .
        </span>
      </div>
    </footer>
  </div>
)
