import { graphql, StaticQuery } from 'gatsby'
import 'modern-normalize/modern-normalize.css'
import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Footer from './Footer'
import './globalStyles.css'
import Meta from './Meta'
import Nav from './Nav'

export default ({ children, meta, title }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            googleTrackingId
            socialMediaCard {
              image
            }
          }
        }
      `}
      render={data => {
        const { siteTitle, socialMediaCard, googleTrackingId } =
            data.settingsYaml || {},
          subNav = {
            posts: false
          }

        return (
          <Fragment>
            <Helmet
              defaultTitle={siteTitle}
              titleTemplate={`%s | ${siteTitle}`}
            >
              {title}
              <link href="https://ucarecdn.com" rel="preconnect" crossorigin />
              <link rel="dns-prefetch" href="https://ucarecdn.com" />
              <script
                type="text/javascript"
                id="cookieinfo"
                src="//cookieinfoscript.com/js/cookieinfo.min.js"
                data-bg="var(--primary)"
                data-fg="#FFFFFF"
                data-link="var(--darkBrown)"
                data-divlink="var(--midGrey)"
                data-divlinkbg="var(--darkBrown)"
                data-cookie="CookieInfoScript"
                data-text-align="left"
                data-close-text="Entendido!"
                data-font-size="12px"
                data-font-family="var(--font-primary)"
                data-linkmsg="Más información"
                data-message="Usamos cookies para mejorar su experiencia. Al seguir navegando por este sitio, acepta nuestro uso de cookies."
              ></script>

              {/* Add font link tags here */}
            </Helmet>

            <Meta
              googleTrackingId={googleTrackingId}
              absoluteImageUrl={
                socialMediaCard &&
                socialMediaCard.image &&
                socialMediaCard.image
              }
              {...meta}
              {...data.settingsYaml}
            />

            <Nav subNav={subNav} />

            <Fragment>{children}</Fragment>

            <Footer />
          </Fragment>
        )
      }}
    />
  )
}
