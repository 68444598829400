import PropTypes from 'prop-types'
import React from 'react'
import Content from './Content'
import Image from './Image'
import './PageHeader.css'

const PageHeader = ({
  button,
  title,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => {
  if (large) className += ' PageHeader-large'
  return (
    <div className={`PageHeader relative ${className}`}>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt="Background image"
          size="cover"
        />
      )}
      <div className="container relative">
        {title && <h1 className="PageHeader--Title">{title}</h1>}
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={subtitle} />
        )}
        {button && (
          <a href="https://cafeteriafama.com/Carta.pdf" target="_blank" rel="noopener noreferrer">
            <button
              className="Button"
              type="button"
            >
              {button}
            </button>
          </a>
        )}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  button: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  descriptionTitle: PropTypes.string,
  imagesDescription: PropTypes.shape({
    image1: PropTypes.string,
    image2: PropTypes.string,
    image3: PropTypes.string
  })
}

export default PageHeader
