import { Location } from '@reach/router'
import { Link } from 'gatsby'
import React, { Component } from 'react'
import { Menu, X } from 'react-feather'
import Logo from './Logo'
import './Nav.css'

export class Navigation extends Component {
  state = {
    active: false,
    activeSubNav: false,
    currentPath: false,
    scrolled: false
  }

  componentDidMount = () => {
    this.setState({ currentPath: this.props.location.pathname })
    window.addEventListener('scroll', () => {
      const documentScroll = document.documentElement.scrollTop
      this.setState({
        scrolled: documentScroll > 0 ? true : false
      })
    })
  }

  handleMenuToggle = () => {
    document.body.style = `overflow: ${this.state.active ? 'auto' : 'hidden'}`
    this.setState({ active: !this.state.active })
  }

  // Only close nav if it is open
  handleLinkClick = () => this.state.active && this.handleMenuToggle()

  toggleSubNav = subNav =>
    this.setState({
      activeSubNav: this.state.activeSubNav === subNav ? false : subNav
    })

  render() {
    const { active } = this.state,
      NavLink = ({ to, className, children, ...props }) => {
        if (to === this.state.currentPath) {
          return (
            <p
              className={`NavLink ${
                to === this.state.currentPath ? 'active' : ''
              } ${className} ${
                this.state.scrolled && !this.state.active ? 'scroll' : ''
              }`}
            >
              {children}
            </p>
          )
        }
        return (
          <Link
            to={to}
            className={`NavLink ${
              to === this.state.currentPath ? 'active' : ''
            } ${className} ${
              this.state.scrolled && !this.state.active ? 'scroll' : ''
            }`}
            onClick={this.handleLinkClick}
            {...props}
          >
            {children}
          </Link>
        )
      }

    return (
      <nav
        className={`Nav${active ? ' Nav-active' : ''}${
          this.state.scrolled ? ' scroll' : ''
        }`}
      >
        <div className="Nav--Container container">
          {this.state.currentPath === '/' ? (
            <div className={`Nav--Logo${this.state.scrolled ? ' scroll' : ''}`}>
              {this.state.scrolled ? <Logo /> : 'F.A.M.A'}
            </div>
          ) : (
            <Link
              to="/"
              onClick={this.handleLinkClick}
              className={`Nav--Logo${this.state.scrolled ? ' scroll' : ''}`}
            >
              {this.state.scrolled ? <Logo /> : 'F.A.M.A'}
            </Link>
          )}
          <div className="Nav--Links">
            <NavLink to="/">Inicio</NavLink>
            <NavLink to="/contact/">¿Dónde estamos?</NavLink>
            {/* <NavLink to="/components/">Sobre nosotros</NavLink> */}
            {/* <div
              className={`Nav--Group ${
                this.state.activeSubNav === 'posts' ? 'active' : ''
              }`}
            >
              <span
                className={`NavLink Nav--GroupParent ${
                  this.props.location.pathname.includes('posts') ||
                  this.props.location.pathname.includes('blog') ||
                  this.props.location.pathname.includes('post-categories')
                    ? 'active'
                    : ''
                }${this.state.scrolled && !this.state.active ? ' scroll' : ''}`}
                onClick={() => this.toggleSubNav('posts')}
              >
                Blog
                <div className="Nav--GroupLinks">
                  <NavLink to="/blog/" className="Nav--GroupLink">
                    All Posts
                  </NavLink>
                  {subNav.posts.map((link, index) => (
                    <NavLink
                      to={link.slug}
                      key={'posts-subnav-link-' + index}
                      className="Nav--GroupLink"
                    >
                      {link.title}
                    </NavLink>
                  ))}
                </div>
              </span>
            </div> */}
          </div>
          <button
            className="Button-blank Nav--MenuButton"
            onClick={this.handleMenuToggle}
          >
            {active ? (
              <X />
            ) : (
              <Menu color={this.state.scrolled ? 'var(--primary)' : 'black'} />
            )}
          </button>
        </div>
      </nav>
    )
  }
}

export default ({ subNav }) => (
  <Location>{route => <Navigation subNav={subNav} {...route} />}</Location>
)
